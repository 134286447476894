<template>
  <div>
    <statement-komisi-card :office-id="officeId" v-if="mode === 'card'" />
    <statement-komisi-table :office-id="officeId" v-else />
  </div>
</template>

<script>
import StatementKomisiCard from './statement-komisi-card.vue';
import StatementKomisiTable from './statement-komisi-table.vue';

export default {
  components: {
    StatementKomisiCard,
    StatementKomisiTable
  },
  props: {
    officeId: String,
  },
  computed: {
    mode() {
      return 'card'
    }
  }
}
</script>

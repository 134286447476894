<template>
  <base-wrapper>
    <statement-komisi :office-id="me.office_id"/>
  </base-wrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import StatementKomisi from '@/components/mitra-usaha/statement-komisi.vue';

export default {
  components: { StatementKomisi },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    })
  }
};
</script>

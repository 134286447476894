<template>
  <base-wrapper>
    <base-card padless>
      <nav class="flex rounded-t-lg border-b border-gray-200 bg-white">
        <button
          v-for="(tab, index) in tabs"
          :key="index"
          type="button"
          class="max-w-xs flex-1 overflow-hidden border-r border-gray-200 py-4 px-4 text-center text-sm font-medium hover:bg-gray-50"
          :class="
            tab === activeTab ? 'bg-gray-50 text-green-600' : 'text-gray-500'
          "
          @click="changeTab(tab)"
        >
          <span>{{ tab }}</span>
        </button>
      </nav>

      <div class="px-4 py-5 sm:p-6">
        <keep-alive>
          <cashbill-nuera-table
            v-if="activeTab === 'Cashbill Nuera'"
            :columns="['code', 'date', 'total_pv', 'total_bv', 'stockist_code']"
          />
          <member-purchase-order-table
            v-if="activeTab === 'Cashbill'"
            :columns="['code', 'date', 'total_pv', 'total_bv', 'stockist_code']"
          />
        </keep-alive>
      </div>
    </base-card>
  </base-wrapper>
</template>

<script>
import CashbillNueraTable from '@/components/cashbill-nuera/cashbill-nuera-table.vue';
import MemberPurchaseOrderTable from '@/components/order/member-purchase-order-table.vue';

export default {
  name: 'Home',
  components: {
    CashbillNueraTable,
    MemberPurchaseOrderTable,
  },
  data() {
    return {
      tabs: ['Cashbill', 'Cashbill Nuera'],
      activeTab: 'Cashbill',
    };
  },
  methods: {
    changeTab(tab) {
      this.activeTab = tab;
    },
  },
};
</script>

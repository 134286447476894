<template>
  <base-card class="space-y-6 overflow-x-auto">
    <img src="@/assets/images/kop.png" width="300px" />

    <table class="w-full">
      <tr class="class-ttext-op">
        <td colspan="7">
          <p><strong>STATEMENT KOMISI BULANAN</strong></p>
          <p>Periode : {{ statementCommission.period }}</p>
        </td>
        <td colspan="6">
          <p>Kepada Yth,</p>
          <p>Bpk/Ibu {{ statementCommission.office.name }}</p>
          <p>{{ statementCommission.office.address }}</p>
          <p v-if="statementCommission.office.addres2">{{ statementCommission.office.addres2 }}</p>
          <p>{{ statementCommission.office.kta }}</p>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <p>No.Tetap</p>
          <p>Nama Mitra Usaha</p>
          <p>Peringkat</p>
        </td>
        <td colspan="5">
          <p>: {{ statementCommission.office.code }} <template v-if="statementCommission.office.temp_code">/ {{ statementCommission.office.temp_code }}</template></p>
          <p>: {{ statementCommission.office.name }}</p>
          <p>: {{ convertStage(statementCommission.office.stage) }}</p>
        </td>
        <td colspan="2">
          <p>No Upline</p>
          <p>Nama Upline</p>
          <p>Peringkat</p>
        </td>
        <td colspan="5">
          <p>: {{ statementCommission.office.upline_code }}</p>
          <p>: {{ statementCommission.office.upline_name }}</p>
          <p>: {{ convertStage(statementCommission.office.upline_stage) }}</p>
        </td>
      </tr>
      <tr>
        <td colspan="7">
          <p><strong>Informasi Data Cashbill</strong></p>
        </td>
        <td colspan="5">
          <p><strong>Informasi Data Komisi Bulanan</strong></p>
        </td>
      </tr>
      <tr>
        <td>Tanggal</td>
        <td class="text-center">No. Casbill</td>
        <td colspan="3" class="text-center">PV</td>
        <td class="text-right">BV</td>
        <td></td>
        <td colspan="3">Komisi Pengembangan</td>
        <td>:</td>
        <td colspan="2" class="text-right">{{ statementCommission.office.rbt_kembang | toCurrency }}</td>
      </tr>
      <tr>
        <td>{{ statementCommission.transactions?.[0]?.date }}</td>
        <td class="text-center">
          <p v-if="statementCommission.transactions?.[0]?.office_code && statementCommission.transactions?.[0]?.code">{{ statementCommission.transactions?.[0]?.office_code }}-{{ statementCommission.transactions?.[0]?.code }}</p>
        </td>
        <td colspan="3" class="text-center">
          <p v-if="statementCommission.transactions?.[0]?.pv > 0">{{ statementCommission.transactions?.[0]?.pv | toCurrency }}</p>
        </td>
        <td class="text-right">
          <p v-if="statementCommission.transactions?.[0]?.bv > 0">{{ statementCommission.transactions?.[0]?.bv | toCurrency }}</p>
        </td>
        <td></td>
        <td colspan="3">Komisi Karier</td>
        <td>:</td>
        <td colspan="2" class="text-right">{{ statementCommission.office.rbt_karir | toCurrency }}</td>
      </tr>
      <tr>
        <td>{{ statementCommission.transactions?.[1]?.date }}</td>
        <td class="text-center">
          <p v-if="statementCommission.transactions?.[1]?.office_code && statementCommission.transactions?.[1]?.code">{{ statementCommission.transactions?.[1]?.office_code }}-{{ statementCommission.transactions?.[1]?.code }}</p>
        </td>
        <td colspan="3" class="text-center">
          <p v-if="statementCommission.transactions?.[1]?.pv > 0">{{ statementCommission.transactions?.[1]?.pv | toCurrency }}</p>
        </td>
        <td class="text-right">
          <p v-if="statementCommission.transactions?.[1]?.bv > 0">{{ statementCommission.transactions?.[1]?.bv | toCurrency }}</p>
        </td>
        <td></td>
        <td colspan="3">Komisi Kepemimpinan</td>
        <td>:</td>
        <td colspan="2" class="text-right">{{ statementCommission.office.rbt_pimpin | toCurrency }}</td>
      </tr>
      <tr>
        <td>{{ statementCommission.transactions?.[2]?.date }}</td>
        <td class="text-center">
          <p v-if="statementCommission.transactions?.[2]?.office_code && statementCommission.transactions?.[2]?.code">{{ statementCommission.transactions?.[2]?.office_code }}-{{ statementCommission.transactions?.[2]?.code }}</p>
        </td>
        <td colspan="3" class="text-center">
          <p v-if="statementCommission.transactions?.[2]?.pv > 0">{{ statementCommission.transactions?.[2]?.pv | toCurrency }}</p>
        </td>
        <td class="text-right">
          <p v-if="statementCommission.transactions?.[2]?.bv > 0">{{ statementCommission.transactions?.[2]?.bv | toCurrency }}</p>
        </td>
        <td></td>
        <td colspan="3">Komisi Break Away</td>
        <td>:</td>
        <td colspan="2" class="text-right">{{ statementCommission.office.rbt_break_away | toCurrency }}</td>
      </tr>
      <tr>
        <td>{{ statementCommission.transactions?.[3]?.date }}</td>
        <td class="text-center">
          <p v-if="statementCommission.transactions?.[3]?.office_code && statementCommission.transactions?.[3]?.code">{{ statementCommission.transactions?.[3]?.office_code }}-{{ statementCommission.transactions?.[3]?.code }}</p>
        </td>
        <td colspan="3" class="text-center">
          <p v-if="statementCommission.transactions?.[3]?.pv > 0">{{ statementCommission.transactions?.[3]?.pv | toCurrency }}</p>
        </td>
        <td class="text-right">
          <p v-if="statementCommission.transactions?.[3]?.bv > 0">{{ statementCommission.transactions?.[3]?.bv | toCurrency }}</p>
        </td>
        <td></td>
        <td colspan="3">Komisi Diamond</td>
        <td>:</td>
        <td colspan="2" class="text-right">{{ statementCommission.office.rbt_diamond | toCurrency }}</td>
      </tr>
      <tr>
        <td>{{ statementCommission.transactions?.[4]?.date }}</td>
        <td class="text-center">
          <p v-if="statementCommission.transactions?.[4]?.office_code && statementCommission.transactions?.[4]?.code">{{ statementCommission.transactions?.[4]?.office_code }}-{{ statementCommission.transactions?.[4]?.code }}</p>
        </td>
        <td colspan="3" class="text-center">
          <p v-if="statementCommission.transactions?.[4]?.pv > 0">{{ statementCommission.transactions?.[4]?.pv | toCurrency }}</p>
        </td>
        <td class="text-right">
          <p v-if="statementCommission.transactions?.[4]?.bv > 0">{{ statementCommission.transactions?.[4]?.bv | toCurrency }}</p>
        </td>
        <td></td>
        <td colspan="3">Komisi Executive Diamond</td>
        <td>:</td>
        <td colspan="2" class="text-right">{{ statementCommission.office.rbt_executive_diamond | toCurrency }}</td>
      </tr>
      <tr>
        <td>{{ statementCommission.transactions?.[5]?.date }}</td>
        <td class="text-center">
          <p v-if="statementCommission.transactions?.[5]?.office_code && statementCommission.transactions?.[5]?.code">{{ statementCommission.transactions?.[5]?.office_code }}-{{ statementCommission.transactions?.[5]?.code }}</p>
        </td>
        <td colspan="3" class="text-center">
          <p v-if="statementCommission.transactions?.[5]?.pv > 0">{{ statementCommission.transactions?.[5]?.pv | toCurrency }}</p>
        </td>
        <td class="text-right">
          <p v-if="statementCommission.transactions?.[5]?.bv > 0">{{ statementCommission.transactions?.[5]?.bv | toCurrency }}</p>
        </td>
        <td></td>
        <td colspan="3">Komisi Double Diamond</td>
        <td>:</td>
        <td colspan="2" class="text-right">{{ statementCommission.office.rbt_double_diamond | toCurrency }}</td>
      </tr>
      <tr>
        <td>{{ statementCommission.transactions?.[6]?.date }}</td>
        <td class="text-center">
          <p v-if="statementCommission.transactions?.[6]?.office_code && statementCommission.transactions?.[6]?.code">{{ statementCommission.transactions?.[6]?.office_code }}-{{ statementCommission.transactions?.[6]?.code }}</p>
        </td>
        <td colspan="3" class="text-center">
          <p v-if="statementCommission.transactions?.[6]?.pv > 0">{{ statementCommission.transactions?.[6]?.pv | toCurrency }}</p>
        </td>
        <td class="text-right">
          <p v-if="statementCommission.transactions?.[6]?.bv > 0">{{ statementCommission.transactions?.[6]?.bv | toCurrency }}</p>
        </td>
        <td></td>
        <td colspan="3">Komisi Crown Diamond</td>
        <td>:</td>
        <td colspan="2" class="text-right">{{ statementCommission.office.rbt_crown_diamond | toCurrency }}</td>
      </tr>
      <tr>
        <td>{{ statementCommission.transactions?.[7]?.date }}</td>
        <td class="text-center">
          <p v-if="statementCommission.transactions?.[7]?.office_code && statementCommission.transactions?.[7]?.code">{{ statementCommission.transactions?.[7]?.office_code }}-{{ statementCommission.transactions?.[7]?.code }}</p>
        </td>
        <td colspan="3" class="text-center">
          <p v-if="statementCommission.transactions?.[7]?.pv > 0">{{ statementCommission.transactions?.[7]?.pv | toCurrency }}</p>
        </td>
        <td class="text-right">
          <p v-if="statementCommission.transactions?.[7]?.bv > 0">{{ statementCommission.transactions?.[7]?.bv | toCurrency }}</p>
        </td>
        <td></td>
        <td colspan="3">Komisi Pass Up</td>
        <td>:</td>
        <td colspan="2" class="text-right">{{ statementCommission.office.rbt_pass_up | toCurrency }}</td>
      </tr>
      <tr>
        <td>{{ statementCommission.transactions?.[8]?.date }}</td>
        <td class="text-center">
          <p v-if="statementCommission.transactions?.[8]?.office_code && statementCommission.transactions?.[8]?.code">{{ statementCommission.transactions?.[8]?.office_code }}-{{ statementCommission.transactions?.[8]?.code }}</p>
        </td>
        <td colspan="3" class="text-center">
          <p v-if="statementCommission.transactions?.[8]?.pv > 0">{{ statementCommission.transactions?.[8]?.pv | toCurrency }}</p>
        </td>
        <td class="text-right">
          <p v-if="statementCommission.transactions?.[8]?.bv > 0">{{ statementCommission.transactions?.[8]?.bv | toCurrency }}</p>
        </td>
        <td colspan="3">
          <p v-if="statementCommission.office.rbt_promo > 0">Rabat Promo</p>
        </td>
        <td>
          <p v-if="statementCommission.office.rbt_promo > 0">:</p>
        </td>
        <td colspan="2" class="text-right">
          <p v-if="statementCommission.office.rbt_promo > 0">{{ statementCommission.office.rbt_promo | toCurrency }}</p>
        </td>
      </tr>
      <tr>
        <td colspan="7"></td>
        <td colspan="3">
          <p v-if="statementCommission.office.rbt_bos > 0">Rabat Aksi Bos</p>
        </td>
        <td>
          <p v-if="statementCommission.office.rbt_bos > 0">:</p>
        </td>
        <td colspan="2" class="text-right">
          <p v-if="statementCommission.office.rbt_bos > 0">{{ statementCommission.office.rbt_bos | toCurrency }}</p>
        </td>
      </tr>
      <tr>
        <td colspan="7"></td>
        <td colspan="3">
          <p v-if="statementCommission.office.rbt_royalti > 0"><strong>Rabat Royalti Tahun 2021</strong></p>
        </td>
        <td>
          <p v-if="statementCommission.office.rbt_royalti > 0">:</p>
        </td>
        <td colspan="2" class="text-right">
          <p v-if="statementCommission.office.rbt_royalti > 0">{{ statementCommission.office.rbt_royalti | toCurrency }}</p>
        </td>
      </tr>
      <tr>
        <td colspan="7"></td>
        <td colspan="3">
          <p v-if="statementCommission.office.bpud > 0">Biaya Perpanjangan Anggota</p>
        </td>
        <td>
          <p v-if="statementCommission.office.bpud > 0">:</p>
        </td>
        <td colspan="2" class="text-right">
          <p v-if="statementCommission.office.bpud > 0">{{ statementCommission.office.bpud | toCurrency }}</p>
        </td>
      </tr>
      <tr>
        <td colspan="2">Personal Omzet</td>
        <td>:</td>
        <td colspan="2" class="text-center">{{ statementCommission.office.pvpri | toCurrency }}</td>
        <td class="text-right">{{ statementCommission.office.bvpri | toCurrency }}</td>
        <td></td>
        <td colspan="6" class="text-right">-------------- +</td>
      </tr>
      <tr>
        <td colspan="2">Omzet Group Total</td>
        <td>:</td>
        <td colspan="2" class="text-center">{{ statementCommission.office.pvgroup | toCurrency }}</td>
        <td class="text-right">{{ statementCommission.office.bvgroup | toCurrency }}</td>
        <td></td>
        <td colspan="3">Total Komisi Bulan ini</td>
        <td>:</td>
        <td colspan="2" class="text-right">{{ totalCommission | toCurrency }}</td>
      </tr>
      <tr>
        <td colspan="2"></td>
        <td colspan="3" class="text-center">--------- +</td>
        <td class="text-right">--------- +</td>
        <td></td>
        <td colspan="3">
          <p v-if="statementCommission.office.pajak > 0">Angsuran Pajak PPh 21</p>
        </td>
        <td>
          <p v-if="statementCommission.office.pajak > 0">:</p>
        </td>
        <td colspan="2" class="text-right">
          <p v-if="statementCommission.office.pajak > 0">{{ statementCommission.office.pajak | toCurrency }}</p>
        </td>
      </tr>
      <tr>
        <td colspan="2">Total Omzet</td>
        <td>:</td>
        <td colspan="2" class="text-center">{{ totalOmset.pv | toCurrency }}</td>
        <td class="text-right">{{ totalOmset.bv | toCurrency }}</td>
        <td></td>
        <td colspan="3">
          <p v-if="statementCommission.office.pending > 0">Akm Rabat Pending Bulan Lalu</p>
        </td>
        <td>
          <p v-if="statementCommission.office.pending > 0">:</p>
        </td>
        <td colspan="2" class="text-right">
          <p v-if="statementCommission.office.pending > 0">{{ statementCommission.office.pending | toCurrency }}</p>
        </td>
      </tr>
      <tr>
        <td colspan="7"></td>
        <td colspan="3">
          <p v-if="statementCommission.office.biaya > 0">Biaya Transfer</p>
        </td>
        <td>
          <p v-if="statementCommission.office.biaya > 0">:</p>
        </td>
        <td colspan="2" class="text-right">
          <p v-if="statementCommission.office.biaya > 0">{{ statementCommission.office.biaya | toCurrency }}</p>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <p v-if="statementCommission.office.stage === 'D'">Total PV Bulan Lalu</p>
        </td>
        <td>
          <p v-if="statementCommission.office.stage === 'D'">:</p>
        </td>
        <td colspan="2" class="text-center">
          <p v-if="statementCommission.office.stage === 'D'">{{ statementCommission.office.pvlalu | toCurrency }}</p>
        </td>
        <td></td>
        <td></td>
        <td colspan="6" class="text-right">-------------- -</td>
      </tr>
      <tr>
        <td colspan="2">
          <p v-if="statementCommission.office.stage === 'D'">Total PV Akumulatif</p>
        </td>
        <td>
          <p v-if="statementCommission.office.stage === 'D'">:</p>
        </td>
        <td colspan="2" class="text-center">
          <p v-if="statementCommission.office.stage === 'D'">{{ totalPv | toCurrency }}</p>
        </td>
        <td></td>
        <td></td>
        <td colspan="3">Total Komisi Diterima</td>
        <td>:</td>
        <td colspan="2" class="text-right">{{ totalDiterima | toCurrency }}</td>
      </tr>
      <tr>
        <td colspan="11"></td>
      </tr>
      <tr>
        <td colspan="7"><strong>Rincian Mitra Usaha Langsung</strong></td>
        <td colspan="2" class="text-center"><strong>MitraUsaha</strong></td>
        <td class="text-center"></td>
        <td colspan="4" class="text-center">
          <strong>Manager s/d Crown</strong>
        </td>
      </tr>
      <tr>
        <td><strong>No Tetap</strong></td>
        <td colspan="5"><strong>Nama Mitra Usaha</strong></td>
        <td class="text-center"><strong>Peringkat/PV Akm</strong></td>
        <td class="text-right"><strong>PV</strong></td>
        <td class="text-right"><strong>BV</strong></td>
        <td class="text-center"><strong>Pass Up</strong></td>
        <td class="text-right"><strong>PV</strong></td>
        <td colspan="2" class="text-right"><strong>BV</strong></td>
      </tr>
      <!-- loop -->
      <tr v-for="(trx, index) in statementCommission.upline_transactions" :key="index">
        <td>{{ trx.office_code }}</td>
        <td colspan="5">{{ trx.office_name }}</td>
        <td class="text-center">
          <p v-if="trx.stage !== 'D'">{{ trx.stage }}</p>
          <p v-else>{{ trx.pvlalu + trx.pvpri + trx.pvgroup | toCurrency }}</p>
        </td>
        <td class="text-right">
          <p v-if="trx.stage === 'D'">{{ trx.pvpri + trx.pvgroup | toCurrency }}</p>
        </td>
        <td class="text-right">
          <p v-if="trx.stage === 'D'">{{ trx.bvpri + trx.bvgroup | toCurrency }}</p>
        </td>
        <td class="text-center">
          {{ trx.dipup | toCurrency }}
        </td>
        <td class="text-right">
          <p v-if="trx.stage !== 'D'">{{ trx.pvpri + trx.pvgroup | toCurrency }}</p>
        </td>
        <td colspan="2" class="text-right">
          <p v-if="trx.stage !== 'D'">{{ trx.bvpri + trx.bvgroup | toCurrency }}</p>
        </td>
      </tr>
      <tr>
        <td colspan="7"></td>
        <td class="text-right"><strong>-------</strong></td>
        <td class="text-right"><strong>-------</strong></td>
        <td class="text-center"><strong>------</strong></td>
        <td class="text-right"><strong>-------------</strong></td>
        <td colspan="2" class="text-right">-------------- +</td>
      </tr>
      <tr>
        <td colspan="7"></td>
        <td class="text-right"><strong>{{ totalUplineTrx.pv | toCurrency }}</strong></td>
        <td class="text-right"><strong>{{ totalUplineTrx.bv | toCurrency }}</strong></td>
        <td class="text-center"><strong>{{ totalUplineTrx.dipup | toCurrency }}</strong></td>
        <td class="text-right"><strong>{{ totalUplineTrx.pv | toCurrency }}</strong></td>
        <td colspan="2" class="text-right"><strong> {{ totalUplineTrx.bv | toCurrency }}</strong></td>
      </tr>
      <tr>
        <td colspan="11">
          <p>{{ message }} <strong>{{ statementCommission.office.bonus_office }}</strong></p>
        </td>
      </tr>
      <tr>
        <td colspan="11">
          <p v-if="description">{{ description }}</p>
        </td>
      </tr>
      <tr></tr>
      <tr>
        <td colspan="11"></td>
      </tr>
      <tr></tr>
      <tr>
        <td colspan="11"></td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <template v-if="statementCommission.office.bpud > 0 && statementCommission.office.bpud < 15000">
        <tr>
          <td colspan="8">
            <strong><u>KTA BELUM DICETAK</u></strong>
          </td>
        </tr>
        <tr>
          <td colspan="6">Biaya Perpanjangan Keanggotaan</td>
          <td>:</td>
          <td>{{ 15000 | toCurrency }}</td>
        </tr>
        <tr>
          <td colspan="6">Akumulasi Total Biaya Renual Lalu</td>
          <td>:</td>
          <td>{{ statementCommission.office.akmbpud | toCurrency }}</td>
        </tr>
        <tr>
          <td colspan="6">Biaya Renual Dipotong Komisi Bulan Ini</td>
          <td>:</td>
          <td>{{ statementCommission.office.bpud | toCurrency }}</td>
        </tr>
        <tr>
          <td colspan="6">Kekurangan Biaya Renual</td>
          <td>:</td>
          <td>{{ Math.abs(15000 - statementCommission.office.akmbpud - statementCommission.office.bpud) }}</td>
        </tr>
      </template>
      <tr>
        <td colspan="7">
          <strong>CHAMPIONT POINT 2023 - 2026 </strong><br />
          Wisata Luar Negeri<br />
          Dan Atau Wisata Regili<br /><br />
          Poin CP Lalu &nbsp;: {{ statementCommission.office.cplalu }}
          <br />
          Poin CP &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : {{ statementCommission.office.cp }}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          --- +<br />
          Total Poin CP : {{ statementCommission.office.cp + statementCommission.office.cplalu + statementCommission.office.cptukar }}
        </td>
        <td colspan="6">
          <strong>MORESKIN MORE-REWARD 2023</strong><br />
          More Saving - More Holidays - More Religiously<br />
          Periode : 1 Januari s/d 31 Desember 2023<br /><br />
          Poin Lalu &nbsp;: {{ statementCommission.office.pmslalu }}
          <br />
          Poin &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; : {{ statementCommission.office.pms }}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          --- +<br />
          Total Poin : {{ statementCommission.office.pms + statementCommission.office.pmslalu + statementCommission.office.pmstukar }}
        </td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td></td>
      </tr>
      <tr>
        <td colspan="15">
          <u><strong>Informasi :</strong></u>
        </td>
      </tr>
      <tr>
        <td colspan="15">
          <strong
            >SOSIALISASI PERATURAN MENTERI PERDAGANGAN TENTANG LARANGAN BAGI
            MITRA USAHA BERJUALAN DI MARKETPLACE DAN PERATURAN PERDAGANGAN
            MELALUI SISTEM ELEKTRONIK :
            <a href="https://member.naturalnusantara.co.id/fileinfo.php">
              KLIK DISINI</a
            ></strong
          >
        </td>
      </tr>
      <tr>
        <td colspan="15">
          <template v-if="statementCommission.info">
            <p v-for="(info, key) in Object.values(statementCommission.info)" :key="key">{{ info }}</p>
          </template>
        </td>
      </tr>
    </table>
  </base-card>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { sum } from '../../utils/array';

export default {
  mixins: [requestMixin],
  emits: ['update:loading'],
  props: {
    officeId: String
  },
  data() {
    return {
      statementCommission: {
        period: null,
        office: {
          code: null,
          temp_code: null,
          name: null,
          stage: null,
          address: null,
          addres2: null,
          kta: null,
          upline_code: null,
          upline_name: null,
          upline_stage: null,
          rbt_kembang: null,
          rbt_karir: null,
          rbt_pimpin: null,
          rbt_break_away: null,
          rbt_diamond: null,
          rbt_executive_diamond: null,
          rbt_double_diamond: null,
          rbt_crown_diamond: null,
          rbt_pass_up: null,
          rbt_promo: null,
          rbt_bos: null,
          rbt_royalti: null,
          bpud: null,
          pvpri: null,
          bvpri: null,
          pvgroup: null,
          bvgroup: null,
          pajak: null,
          pending: null,
          biaya: null,
          pvlalu: null,
          stockist_code: null,
          transfer: null,
          bonus_office: null,
          bank: null,
          bank_branch: null,
          bank_no: null,
          bank_name: null,
          akmbpud: null,
          cp: null,
          cplalu: null,
          cptukar: null,
          pms: null,
          pmslalu: null,
          pmstukar: null
        }
      },
      transactions: [],
      upline_transactions: [],
      info: {}
    }
  },
  computed: {
    message() {
      if (!this.statementCommission.office.stockist_code && this.statementCommission.office.transfer === 'x') {
        return `Tempat Pengambilan Statement di Stockist`
      }

      return 'Tempat Pengambilan Komisi di Stockist'
    },
    description() {
      if (!this.statementCommission.office.stockist_code && this.statementCommission.office.transfer === 'x') {
        if (this.statementCommission.period.split(' ')[0] === 'Oktober') {
          return this.totalDiterima < 25000
            ? `Komisi dipending ke Rekening ${this.statementCommission.office.bank} Cabang ${this.statementCommission.office.bank_branch} No. Rek ${this.statementCommission.office.bank_no} a.n ${this.statementCommission.office.bank_name}`
            : `Komisi dipending ke Rekening ${this.statementCommission.office.bank} Cabang ${this.statementCommission.office.bank_branch} No. Rek ${this.statementCommission.office.bank_no} a.n ${this.statementCommission.office.bank_name}`
        }

        return this.statementCommission.office.biaya > 0
          ? `"Komisi ditransfer ke Rekening Bank ${this.statementCommission.office.bank} Cabang ${this.statementCommission.office.bank_branch} No. Rek ${this.statementCommission.office.bank_no} a.n ${this.statementCommission.office.bank_name}`
          : `Komisi dipending / diakumulasikan dan jika telah memenuhi syarat akan ditransfer ke Rekening Bank ${this.statementCommission.office.bank}  Cabang ${this.statementCommission.office.bank_branch} No. Rekening ${this.statementCommission.office.bank_no} Atas Nama ${this.statementCommission.office.bank_name}`
      }

      return false
    },
    totalCommission() {
      return this.statementCommission.office.rbt_kembang +
        this.statementCommission.office.rbt_karir +
        this.statementCommission.office.rbt_pimpin +
        this.statementCommission.office.rbt_break_away +
        this.statementCommission.office.rbt_diamond +
        this.statementCommission.office.rbt_executive_diamond +
        this.statementCommission.office.rbt_double_diamond +
        this.statementCommission.office.rbt_crown_diamond +
        this.statementCommission.office.rbt_promo +
        this.statementCommission.office.rbt_pass_up -
        this.statementCommission.office.bpud +
        this.statementCommission.office.rbt_bos +
        this.statementCommission.office.rbt_royalti
    },
    totalOmset() {
      return {
        pv: this.statementCommission.office.pvpri + this.statementCommission.office.pvgroup,
        bv: this.statementCommission.office.bvpri + this.statementCommission.office.bvgroup
      }
    },
    totalPv() {
      return this.statementCommission.office.pvlalu + this.statementCommission.office.pvpri + this.statementCommission.office.pvgroup
    },
    totalDiterima() {
      return this.statementCommission.office.rbt_kembang +
        this.statementCommission.office.rbt_karir +
        this.statementCommission.office.rbt_pimpin +
        this.statementCommission.office.rbt_break_away +
        this.statementCommission.office.rbt_diamond +
        this.statementCommission.office.rbt_executive_diamond +
        this.statementCommission.office.rbt_double_diamond +
        this.statementCommission.office.rbt_crown_diamond +
        this.statementCommission.office.rbt_promo +
        this.statementCommission.office.rbt_pass_up -
        this.statementCommission.office.bpud +
        this.statementCommission.office.rbt_bos +
        this.statementCommission.office.rbt_royalti -
        this.statementCommission.office.pajak +
        this.statementCommission.office.pending -
        this.statementCommission.office.biaya
    },
    totalUplineTrx() {
      return {
        pv: sum(this.statementCommission.upline_transactions ?? [], i => i.pvpri + i.pvgroup),
        bv: sum(this.statementCommission.upline_transactions ?? [], i => i.bvpri + i.bvgroup),
        dipup: sum(this.statementCommission.upline_transactions ?? [], i => i.dipup),
      }
    }
  },
  methods: {
    convertStage(stage) {
      return {
        'D': 'Mitra Usaha',
        'M': 'Manager',
        'SM': 'Silver Manager',
        'GM': 'Gold Manager',
        'PM': 'Pearl Manager',
        'EM': 'Emerald Manager',
        'DD': 'Diamond Director',
        'EDD': 'Executive Diamond Director',
        'DDD': 'Double Diamond Director',
        'CDD': 'Crown Diamond Director',
      }[stage]
    },
    async loadCommission() {
      this.$emit('update:loading', true)

      const [res, err] = await this.request(`/api/v1/offices/${this.officeId}/-actions/statement-commission`)

      if (!err) {
        this.statementCommission = res
      }

      this.$emit('update:loading', false)
    },
  },
  mounted() {
    this.loadCommission()
  }
}
</script>
<template>
  <div>
    <div class="space-y-4">
      <div class="flex">
        <mitra-period-select
          v-model="filter.mitra_period_id"
          @change="loadOrders"
        />
      </div>
      <datatable
        :columns="headers"
        :total="orders.meta.page.total"
        :perPage="orders.meta.page.perPage"
        :meta="orders.meta"
        :currentPage="orders.meta.page.currentPage"
        :is-empty="!orders.data.length"
        :scroll-x="false"
        cursor
        @pagechanged="onPageChanged"
      >
        <template v-slot:tbody="{ classes }">
          <tbody :class="classes.tbody">
            <tr
              v-for="(order, index) in orders.data"
              :key="order.id"
              :class="[
                classes.tr,
                index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                'cursor-pointer bg-white hover:bg-green-100',
              ]"
              @click="onDetail(order)"
            >
              <td :class="[classes.td]" v-if="columns.includes('code')">
                <span class="font-bold text-gray-900"
                  >{{ order.attributes.origin_code }} /
                  {{ order.attributes.destination_code }}</span
                >
              </td>
              <td :class="classes.td" v-if="columns.includes('date')">
                {{ order.attributes.createdAt | formatDate('ll') }}
              </td>
              <td
                :class="[classes.td, 'text-right']"
                v-if="columns.includes('total_pv')"
              >
                {{
                  getSingleIncluded(orders, order.relationships.order.data.id)
                    .attributes.grand_total_pv | toCurrency
                }}
              </td>
              <td
                :class="[classes.td, 'text-right']"
                v-if="columns.includes('total_bv')"
              >
                {{
                  (getSingleIncluded(orders, order.relationships.order.data.id)
                    .attributes.grand_total_bv ?? 0) | toCurrency
                }}
              </td>
              <td :class="classes.td" v-if="columns.includes('stockist_code')">
                {{
                  getSingleIncluded(orders, order.relationships.order.data.id)
                    .attributes.origin_office_code
                }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr :class="[classes.tr]">
              <td :class="[classes.td]" colspan="2">Total</td>
              <td :class="[classes.td, 'text-right']">
                {{ summaryOrder.total_pv | toCurrency }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{ summaryOrder.total_bv | toCurrency }}
              </td>
            </tr>
          </tfoot>
        </template>
      </datatable>
    </div>
    <member-purchase-order-detail-modal
      :visible="detailModal.visible"
      :order-id="detailModal.orderId"
      @close="detailModal.visible = false"
    />
    <loading v-if="loadingOrders || this.loadingSummaryOrder" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';
import MemberPurchaseOrderDetailModal from './member-purchase-order-detail-modal.vue';
import MitraPeriodSelect from '@/components/period/mitra-period/mitra-period-select.vue';

export default {
  mixins: [requestMixin],
  components: {
    MemberPurchaseOrderDetailModal,
    MitraPeriodSelect,
  },
  props: {
    columns: Array,
  },
  data() {
    return {
      orders: {
        data: [],
        meta: {
          page: {
            total: 0,
            perPage: 10,
            currentPage: 1,
          },
        },
        include: [],
      },
      summaryOrder: {
        total_pv: 0,
        total_bv: 0,
      },
      filter: {
        mitra_period_id: null,
      },
      loadingOrders: false,
      loadingSummaryOrder: false,
      detailModal: {
        visible: false,
        orderId: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    headers() {
      return [
        { id: 'code', name: 'NMRBLL' },
        { id: 'date', name: 'TGLBLL' },
        { id: 'total_pv', name: 'PV', theadClass: 'text-right' },
        { id: 'total_bv', name: 'BV', theadClass: 'text-right' },
        { id: 'stockist_code', name: 'KODEST' },
      ].filter((header) => this.columns.includes(header.id));
    },
  },
  methods: {
    onPageChanged(page) {
      this.loadOrders(page);
    },
    onDetail(order) {
      this.detailModal.orderId = order.relationships.order.data.id;
      this.detailModal.visible = true;
    },
    async loadOrders(params = {}) {
      this.loadingOrders = true;

      const [res, err] = await this.request('/api/v1/orders', {
        params: {
          'page[limit]': 5,
          'filter[destination_warehouse_id]': this.me.current_warehouse,
          'filter[mitra_period_uuid]': this.filter.mitra_period_id,
          include: 'order',
          'fields[simple-orders]':
            'origin_code,destination_code,createdAt,order',
          'fields[orders]': 'grand_total_pv,grand_total_bv,origin_office_code',
          ...params,
        },
      });

      if (!err) {
        this.orders = res;
      }

      this.loadingOrders = false;
    },
    async loadSummaryOrder() {
      this.loadingSummaryOrder = true;

      const [res, err] = await this.request('/api/v1/orders/-actions/summary', {
        params: {
          'filter[destination_warehouse_id]': this.me.current_warehouse,
          'filter[mitra_period_uuid]': this.filter.mitra_period_id,
        },
      });

      if (!err) {
        this.summaryOrder = res;
      }

      this.loadingSummaryOrder = false;
    },
  },
  activated() {
    this.filter.mitra_period_id = this.me.current_period.id;

    this.loadOrders();
    this.loadSummaryOrder();
  },
};
</script>

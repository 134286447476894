<template>
  <div>
    <base-modal :showing="visible" @close="$emit('close')" @opened="onOpened">
      <div class="flex items-center justify-between">
        <div class="flex items-center space-x-4">
          <div>
            <h3 class="text-lg font-bold leading-6 text-gray-900">
              Detail Cashbill
            </h3>
            <p class="mt-1 max-w-2xl text-sm text-gray-500">
              Detail Cashbill
            </p>
          </div>
        </div>
      </div>
      <div class="mt-6 border-t border-gray-200 pt-6">
        <dl class="grid grid-cols-3 gap-x-4 gap-y-6">
          <div class="w-full">
            <dt class="text-xs text-gray-700">Nomor Cashbill</dt>
            <dd class="mt-1 text-sm font-bold text-gray-900">
              {{ order.data.attributes.origin_code }} / {{ order.data.attributes.destination_code }}
            </dd>
          </div>
          <div class="w-full">
            <dt class="text-xs text-gray-700">Dibuat Tanggal</dt>
            <dd class="mt-1 text-sm font-bold text-gray-900">
              {{ order.data.attributes.createdAt | formatDate('ll') }}
            </dd>
          </div>
          <div class="w-full">
            <dt class="text-xs text-gray-700">Kode Stockist</dt>
            <dd class="mt-1 text-sm font-bold text-gray-900">
              {{ order.data.attributes.origin_office_code }}
            </dd>
          </div>
          <div class="w-full">
            <dt class="text-xs text-gray-700">Kode Mitra Usaha</dt>
            <dd class="mt-1 text-sm font-bold text-gray-900">
              {{ order.data.attributes.destination_office_code }}
            </dd>
          </div>
          <div class="w-full">
            <dt class="text-xs text-gray-700">Total PV</dt>
            <dd class="mt-1 text-sm font-bold text-gray-900">
              {{ order.data.attributes.grand_total_pv | toCurrency }}
            </dd>
          </div>
          <div class="w-full">
            <dt class="text-xs text-gray-700">Total BV</dt>
            <dd class="mt-1 text-sm font-bold text-gray-900">
              {{ (order.data.attributes.grand_total_bv ?? 0) | toCurrency }}
            </dd>
          </div>
          <div class="w-full">
            <dt class="text-xs text-gray-700">Total Harga</dt>
            <dd class="mt-1 text-sm font-bold text-gray-900">
              {{ order.data.attributes.final_price | toCurrency }}
            </dd>
          </div>
          <div class="col-span-3">
            <datatable
              :paginated="false"
              :columns="detailColumns"
              :scroll-x="false"
              :is-empty="!orderDetails.length"
            >
              <template v-slot:tbody="{ classes }">
                <tbody :class="classes.tbody">
                  <tr
                    v-for="orderDetail in orderDetails"
                    :key="orderDetail.id"
                    :class="[classes.tr]"
                  >
                    <td :class="[classes.td]">
                      <span class="font-bold text-gray-900">{{
                        orderDetail.attributes.product_code
                      }}</span>
                    </td>
                    <td :class="[classes.td, 'text-right']">
                      {{ orderDetail.attributes.product_qty | toCurrency }}
                    </td>
                    <td :class="[classes.td, 'text-right']">
                      {{ orderDetail.attributes.point_value | toCurrency }}
                    </td>
                    <td :class="[classes.td, 'text-right']">
                      {{ orderDetail.attributes.bonus_value | toCurrency }}
                    </td>
                  </tr>
                </tbody>
                <tfoot v-if="orderDetails.length">
                  <tr :class="[classes.tr]">
                    <td :class="[classes.td]" colspan="2">Total</td>
                    <td :class="[classes.td, 'text-right']">
                      {{
                        sum(orderDetails, (item) => item.attributes.point_value)
                          | toCurrency
                      }}
                    </td>
                    <td :class="[classes.td, 'text-right']">
                      {{
                        sum(orderDetails, (item) => item.attributes.bonus_value)
                          | toCurrency
                      }}
                    </td>
                  </tr>
                </tfoot>
              </template>
            </datatable>
          </div>
        </dl>
      </div>
    </base-modal>
    <loading v-if="loading" />
  </div>
</template>

<script>
import BaseModal from '@/components/base/BaseModal.vue';
import { requestMixin } from '@/mixins/request/request';
import { sum } from '@/utils/array';

export default {
  mixins: [requestMixin],
  props: {
    visible: Boolean,
    orderId: String,
  },
  emits: ['close'],
  components: {
    BaseModal,
  },
  data() {
    return {
      loading: false,
      order: {
        data: {
          attributes: {},
        },
      },
    };
  },
  computed: {
    detailColumns() {
      return [
        { id: 'product', name: 'KDEBRN' },
        { id: 'qty', name: 'BNY', theadClass: 'text-right' },
        { id: 'pv', name: 'TOTPV', theadClass: 'text-right' },
        { id: 'bv', name: 'TOTBV', theadClass: 'text-right' },
      ];
    },
    orderDetails() {
      return this.getIncludedByType(this.order, 'order-details');
    },
  },
  methods: {
    sum,
    onOpened() {
      this.loadOrder();
    },
    async loadOrder() {
      this.loading = true;

      const [res, error] = await this.request(
        `/api/v1/orders/${this.orderId}`,
        {
          params: {
            include: 'order-details',
            'fields[orders]': 'origin_code,destination_code,createdAt,origin_office_code,destination_office_code,grand_total_pv,grand_total_bv,final_price,order-details',
            'fields[order-details]': 'product_code,product_qty,point_value,bonus_value'
          },
        }
      );

      if (!error) {
        this.order = res;
      }

      this.loading = false;
    },
  },
};
</script>

<template>
  <div>
    <div class="space-y-4">
      <div class="flex">
        <mitra-period-select
          v-model="filter.mitra_period_id"
          @change="loadCashbills"
        />
      </div>
      <datatable
        :columns="headers"
        :total="cashbills.meta.page.total"
        :perPage="cashbills.meta.page.perPage"
        :currentPage="cashbills.meta.page.currentPage"
        :scroll-x="false"
        :is-empty="!cashbills.data.length"
        @pagechanged="onPageChanged"
      >
        <template v-slot:tbody="{ classes }">
          <tbody :class="classes.tbody">
            <tr
              v-for="(cashbill, index) in cashbills.data"
              :key="cashbill.id"
              :class="[
                classes.tr,
                index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                'cursor-pointer bg-white hover:bg-green-100',
              ]"
              @click="onDetail(cashbill)"
            >
              <td :class="[classes.td]" v-if="columns.includes('code')">
                <span class="font-bold text-gray-900">{{
                  cashbill.attributes.code
                }}</span>
              </td>
              <td :class="classes.td" v-if="columns.includes('date')">
                {{ cashbill.attributes.date | formatDate('ll') }}
              </td>
              <td
                :class="[classes.td, 'text-right']"
                v-if="columns.includes('total_pv')"
              >
                {{ cashbill.attributes.total_pv | toCurrency }}
              </td>
              <td
                :class="[classes.td, 'text-right']"
                v-if="columns.includes('total_bv')"
              >
                {{ cashbill.attributes.total_bv | toCurrency }}
              </td>
              <td :class="classes.td" v-if="columns.includes('stockist_code')">
                {{ cashbill.attributes.origin_office_code }}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr :class="[classes.tr]">
              <td :class="[classes.td]" colspan="2">Total</td>
              <td :class="[classes.td, 'text-right']">
                {{ cashbills.meta.total_pv | toCurrency }}
              </td>
              <td :class="[classes.td, 'text-right']">
                {{ cashbills.meta.total_bv | toCurrency }}
              </td>
            </tr>
          </tfoot>
        </template>
      </datatable>
    </div>
    <cashbill-nuera-detail-modal
      :visible="detailModal.visible"
      :cashbillId="detailModal.cashbillId"
      @close="detailModal.visible = false"
    />
    <loading v-if="loading" />
  </div>
</template>

<script>
import { requestMixin } from '@/mixins/request/request';
import { mapGetters } from 'vuex';
import CashbillNueraDetailModal from './cashbill-nuera-detail-modal.vue';
import MitraPeriodSelect from '@/components/period/mitra-period/mitra-period-select.vue';

export default {
  mixins: [requestMixin],
  components: {
    CashbillNueraDetailModal,
    MitraPeriodSelect,
  },
  props: {
    columns: Array,
  },
  data() {
    return {
      cashbills: {
        data: [],
        meta: {
          page: {
            total: 0,
            perPage: 10,
            currentPage: 1,
          },
          total_pv: 0,
          total_bv: 0,
        },
      },
      filter: {
        mitra_period_id: null,
      },
      loading: false,
      detailModal: {
        visible: false,
        cashbillId: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      me: 'auth/getUser',
    }),
    headers() {
      return [
        { id: 'code', name: 'NMRBLL' },
        { id: 'date', name: 'TGLBLL' },
        { id: 'total_pv', name: 'PV', theadClass: 'text-right' },
        { id: 'total_bv', name: 'BV', theadClass: 'text-right' },
        { id: 'stockist_code', name: 'KODEST' },
      ].filter((header) => this.columns.includes(header.id));
    },
  },
  methods: {
    onPageChanged(page) {
      this.loadCashbills({
        'page[number]': page,
      });
    },
    onDetail(cashbill) {
      this.detailModal.cashbillId = cashbill.id;
      this.detailModal.visible = true;
    },
    async loadCashbills(params = {}) {
      this.loading = true;

      const [res, error] = await this.request('/api/v1/cashbills', {
        params: {
          'page[size]': 5,
          'fields[cashbills]': 'code,date,total_pv,total_bv,origin_office_code',
          'filter[destination_office_code]': this.me.office_code,
          'filter[mitra_period_id]': this.filter.mitra_period_id,
          sort: '-createdAt',
          ...params,
        },
      });

      if (!error) {
        this.cashbills = res;
      }

      this.loading = false;
    },
  },
  activated() {
    this.filter.mitra_period_id = this.me.current_period.id;

    this.loadCashbills();
  },
};
</script>
